<template>
  <v-data-table
      class='elevation-1'
      :footer-props="footerProps"
      :headers='headers'
      :items='items'
      item-key='Id'
      :loading='loading'
      :sort-by.sync='pageSortBy'
      sort-desc.sync='false'
  >
    <template v-slot:item.AnsweredDate="{ item }">
      {{ item.AnsweredDate | FormatDateISO }}
    </template>
    <template v-slot:item.actions="{ item }">
      <td style='text-align: center'>
        <button class='linkBtn' type='button' @click='viewDlg(item)'>
          View
        </button>
        <button
            class='linkBtn'
            type='button'
            v-show='allowEdit'
            @click='editDlg(item)'
        >
          Edit
        </button>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ['items', 'loading', 'allowEdit'],
  data() {
    return {
      footerProps: {itemsPerPageOptions: [25, 50]},
      pageSortBy: 'AnsweredDate',
      headers: [
        {
          text: 'Date',
          value: 'AnsDate',
          sortable: true,
          width: '85%'
        },
        // { text: 'PEFR Pred', value: 'PEFRPred', sortable: true, width: '15%' },
        // { text: 'SpO2', value: 'SpO2', sortable: true, width: '15%' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '15%',
          align: 'center'
        }
      ]
    };
  },
  methods: {
    editDlg(item) {
      this.$emit('edit', item);
    },
    viewDlg(item) {
      this.$emit('view', item);
    }
  }
};
</script>

<style scoped></style>
